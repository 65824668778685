<template>
  <view-base ref="base">
    <div class="mb-3 row">
      <div class="col-md-3">
        <multiselect
          v-model="selectedStores"
          :options="stores"
          :multiple="true"
          :searchable="false"
          :internal-search="false"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          :prevent-autofocus="true"
          :placeholder="selectedStoresLabel"
          select-label=""
          deselect-label=""
          label="name"
          track-by="name"
          :preselect-first="false"
          @input="onUpdateFilterData"
        >
          <template slot="selection" slot-scope="{ values, isOpen }">
            <span v-if="values.length == 1 && !isOpen" class="multiselect__single">{{ values[0].name }}</span>
            <span v-if="values.length > 1 && !isOpen" class="multiselect__single">{{ values.length }} {{ $t('lojas selecionadas') }}</span>
          </template>
        </multiselect>
      </div>
      <div class="col-md-4">
        <date-range-picker
          ref="picker"
          v-model="filterDate"
          class="col-12"
          :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
          :max-date="filterDateMaxDate"
          :single-date-picker="false"
          :time-picker="false"
          :show-week-numbers="false"
          :show-dropdowns="false"
          :auto-apply="false"
          :linked-calendars="false"
          :ranges="false"
          dated-format="DD/MM/AAAA"
          @update="onUpdateFilterData"
        >
          <template v-slot:input="picker" style="min-width: 350px;">
            <span>{{ picker.startDate | formatDate }} - {{ picker.endDate | formatDate }}</span>
          </template>
        </date-range-picker>
      </div>
    </div>
    <div class="mb-3">
      <h1>{{ $t('report.deliveries') }}</h1>
      <p>Total: {{ totalOrders }}</p>
      <chart-orders :options="chartOptions" :chart-data="chartData" :styles="myStyles" />
    </div>
    <div class="col-md-4">
      <h3>{{ $t('report.deliveriesByArea') }}</h3>
      <chart-orders-area :options="chartAreaOptions" :chart-data="chartAreaData" :styles="myStyles" />
    </div>
  </view-base>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.card {
  width: 300px;
  display: inline-block;
  vertical-align: middle;
  background: #F0F0F0;
  border-radius: 3px 3px 4px 4px;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  border-top: 1px solid white;

  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
</style>

<script>
import Ripple from 'vue-ripple-directive'
import Multiselect from 'vue-multiselect'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

import axios from 'axios'
import moment from 'moment'
import useJwt from '@/auth/jwt/useJwt'
import ViewBase from '@/views/ViewBase.vue'
import { getUserData } from '@/auth/utils'
import ChartOrders from '@/views/components/ChartOrders.vue'
import ChartOrdersArea from '@/views/components/ChartOrdersArea.vue'

export default {
  components: {
    ViewBase,
    Multiselect,
    DateRangePicker,
    ChartOrders,
    ChartOrdersArea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      ordersToday: [],
      stores: [],
      orders: [],
      ordersArea: [],
      selectedStores: [],
      selectedStoresLabel: '-',
      filterDate: {
        startDate: moment().add(-6, 'days'),
        endDate: moment(),
      },
      filterDateMaxDate: moment().format('YYYY-MM-DD'),
      myStyles: {
        height: '300px',
        width: '100%',
        position: 'relative',
      },
      totalOrders: 0,
      chartData: {
        datasets: [],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
            },
          }],
        },
        height: 300,
        legend: {
          display: false,
        },
      },
      chartAreaData: {
        datasets: [],
      },
      chartAreaOptions: {
        responsive: true,
        maintainAspectRatio: false,
        height: 300,
        legend: {
          display: false,
        },
      },
      totalToday: 0,
      ordersCurrentWeek: [],
      totalCurrentWeek: 0,
      ordersLastWeek: [],
      totalLastWeek: 0,
      ordersLast15: [],
      totalLast15: 0,
      userRole: getUserData().role.code,
      storeId: getUserData().store_id,
      columns: [{
          title: 'Loja',
          name: 'storeName',
          visible: true,
          sortable: true,
          cellstyle: 'table-cell-bold-sigla',
          filtered: false,
      },
      {
          title: 'Código',
          name: 'code',
          visible: true,
          sortable: true,
          cellstyle: 'table-cell-bold-sigla',
          filtered: false,
      },
      {
          title: 'Cliente',
          name: 'eaterName',
          visible: true,
          sortable: true,
          cellstyle: 'table-cell-bold-sigla',
          filtered: false,
      },
      {
          title: 'Hora',
          name: 'formatDate',
          visible: true,
          sortable: true,
          cellstyle: 'table-cell-bold-sigla',
      },
      {
          title: 'Valor',
          name: 'deliveryCost',
          visible: true,
          sortable: true,
          cellstyle: 'table-cell-bold-sigla',
      },
      {
          title: 'Cidade',
          name: 'delivered_city',
          visible: true,
          sortable: true,
          cellstyle: 'table-cell-bold-sigla',
      },
      ],
      sortColunms: [],
      filteredSize: 0,
    }
  },
  mounted() {
    this.loadData()
  },
  created() {
  },
  methods: {
    async loadData() {
      const self = this
      this.$refs.base.showLoading()

      await self.loadStoresList()
      await self.loadOrdersData()

      this.$refs.base.hideLoading()
    },
    loadStoresList() {
      const self = this
      return axios.get(`${process.env.VUE_APP_CORE_ENDPOINT}/store/list`, useJwt.getHeader())
      .then(response => {
        self.stores = response.data.stores ?? []
        self.selectedStoresLabel = `${this.$t('Stores')} (${self.stores.length})`
      })
      .catch(error => {
        console.log('error', error)
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(this.$t(error.response?.data?.error))
      })
    },
    loadOrdersData() {
      const self = this
      axios.defaults.headers.common.Accept = 'application/json'
      axios.defaults.headers.common.Authorization = `Bearer ${window.$cookies.get('access_token')}`

      const storesIds = self.selectedStores.length > 0 ? self.selectedStores.map(obj => obj.id).join(',') : self.stores.map(obj => obj.id).join(',')

      return axios.post(`${process.env.VUE_APP_CORE_ENDPOINT}/report/orders`, {
        stores: storesIds,
        startDate: self.filterDate.startDate,
        endDate: self.filterDate.endDate,
      })
      .then(response => {
        self.totalOrders = response.data.total ?? []
        self.chartData = {
          labels: [],
          datasets: [],
        }
        const newData = {
          labels: [],
          datasets: [{
            backgroundColor: '#004d91',
            data: [],
          }],
        }
        self.orders = response.data.orders ?? []

        if (moment(self.filterDate.startDate).format('DD/MM/YYYY') === moment(self.filterDate.endDate).format('DD/MM/YYYY')) {
          for (let hour = 0; hour < 24; hour += 1) {
            const total = self.orders.filter(o => o.label.toString() === hour.toString())[0] ? self.orders.filter(o => o.label.toString() === hour.toString())[0].total : 0
            newData.labels.push(`${hour}:00`)
            newData.datasets[0].data.push(total)
            console.log('bar', `${hour}:00`, total)
          }
        } else {
          for (let days = moment(self.filterDate.startDate).diff(moment(self.filterDate.endDate), 'days'); days <= 0; days += 1) {
            const currentDate = moment(self.filterDate.endDate).add(days, 'days').format('DD/MM/YYYY')
            const total = self.orders.filter(o => o.label === currentDate)[0] ? self.orders.filter(o => o.label === currentDate)[0].total : 0
            newData.labels.push(currentDate)
            newData.datasets[0].data.push(total)
            console.log('bar', currentDate, total)
          }
        }
        self.chartData = newData

        self.chartAreaData = {
          labels: [],
          datasets: [{
            backgroundColor: [],
            data: [],
          }],
        }
        const newAreaData = {
          labels: [],
          datasets: [{
            backgroundColor: ['#004d91', '#00BFFF', '#6495ED'],
            data: [],
          }],
        }
        self.ordersArea = response.data.ordersArea ?? []
        for (let currentArea = 1; currentArea < 4; currentArea += 1) {
          const total = self.ordersArea.filter(o => o.label.toString() === currentArea.toString())[0] ? self.ordersArea.filter(o => o.label.toString() === currentArea.toString())[0].total : 0
          newAreaData.labels.push(`Area ${currentArea}`)
          newAreaData.datasets[0].data.push(total)
          console.log('pie', `Area ${currentArea}`, total)
        }
        self.chartAreaData = newAreaData
      })
      .catch(error => {
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(this.$t(error.response?.data?.error))
      })
    },
    merchantLoadOrdersDeliveredToday() {
      const self = this
      this.$refs.base.showLoading()
      self.totalToday = 0
      axios.get(`${process.env.VUE_APP_CORE_ENDPOINT}/store/${this.storeId}/orders/delivered/today`, useJwt.getHeader())
      .then(response => {
        self.ordersToday = response.data.orders ?? []
        self.ordersToday.forEach((order, index) => {
          self.totalToday += parseFloat(self.ordersToday[index].deliveryCost)
          self.ordersToday[index].storeName = self.ordersToday[index].store.name
          self.ordersToday[index].formatDate = moment(self.ordersToday[index].delivered_at).format('DD/MM/YYYY HH:mm')
          self.ordersToday[index].link = `https://www.google.com/maps/search/${self.ordersToday[index].delivered_lat},${self.ordersToday[index].delivered_lon}`
          })
      })
      .catch(error => {
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(this.$t(error.response?.data?.error))
      })
      .finally(() => {
        this.$refs.base.hideLoading()
      })
    },
    merchantLoadOrdersDeliveredCurrentWeek() {
      const self = this
      this.$refs.base.showLoading()
      self.totalCurrentWeek = 0
      axios.get(`${process.env.VUE_APP_CORE_ENDPOINT}/store/${this.storeId}/orders/delivered/currentWeek`, useJwt.getHeader())
      .then(response => {
        self.ordersCurrentWeek = response.data.orders ?? []
        self.ordersCurrentWeek.forEach((order, index) => {
          self.totalCurrentWeek += parseFloat(self.ordersCurrentWeek[index].deliveryCost)
          self.ordersCurrentWeek[index].storeName = self.ordersCurrentWeek[index].store.name
          self.ordersCurrentWeek[index].formatDate = moment(self.ordersCurrentWeek[index].delivered_at).format('DD/MM/YYYY HH:mm')
          self.ordersCurrentWeek[index].link = `https://www.google.com/maps/search/${self.ordersCurrentWeek[index].delivered_lat},${self.ordersCurrentWeek[index].delivered_lon}`
          })
      })
      .catch(error => {
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(this.$t(error.response?.data?.error))
      })
      .finally(() => {
        this.$refs.base.hideLoading()
      })
    },
    merchantLoadOrdersDeliveredLastWeek() {
      const self = this
      this.$refs.base.showLoading()
      self.totalLastWeek = 0
      axios.get(`${process.env.VUE_APP_CORE_ENDPOINT}/store/${this.storeId}/orders/delivered/lastWeek`, useJwt.getHeader())
      .then(response => {
        self.ordersLastWeek = response.data.orders ?? []
        self.ordersLastWeek.forEach((order, index) => {
          self.totalLastWeek += parseFloat(self.ordersLastWeek[index].deliveryCost)
          self.ordersLastWeek[index].storeName = self.ordersLastWeek[index].store.name
          self.ordersLastWeek[index].formatDate = moment(self.ordersLastWeek[index].delivered_at).format('DD/MM/YYYY HH:mm')
          self.ordersLastWeek[index].link = `https://www.google.com/maps/search/${self.ordersLastWeek[index].delivered_lat},${self.ordersLastWeek[index].delivered_lon}`
          })
      })
      .catch(error => {
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(this.$t(error.response?.data?.error))
      })
      .finally(() => {
        this.$refs.base.hideLoading()
      })
    },
    merchantLoadOrdersDeliveredLast15() {
      const self = this
      this.$refs.base.showLoading()
      self.totalLast15 = 0
      axios.get(`${process.env.VUE_APP_CORE_ENDPOINT}/store/${this.storeId}/orders/delivered/last15`, useJwt.getHeader())
      .then(response => {
        self.ordersLast15 = response.data.orders ?? []
        self.ordersLast15.forEach((order, index) => {
          self.totalLast15 += parseFloat(self.ordersLast15[index].deliveryCost)
          self.ordersLast15[index].storeName = self.ordersLast15[index].store.name
          self.ordersLast15[index].formatDate = moment(self.ordersLast15[index].delivered_at).format('DD/MM/YYYY HH:mm')
          self.ordersLast15[index].link = `https://www.google.com/maps/search/${self.ordersLast15[index].delivered_lat},${self.ordersLast15[index].delivered_lon}`
          })
      })
      .catch(error => {
        console.log('error', error)
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(this.$t(error.response?.data?.error))
      })
      .finally(() => {
        this.$refs.base.hideLoading()
      })
    },
    onUpdateFilterData() {
      const self = this
      self.loadOrdersData()
    },
    onOrderSelected(event, data) {
      window.open(data.link, '_blank')
    },
  },
}
</script>
